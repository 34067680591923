@font-face {
  font-family: "LegacySansStd-Book";
  font-display: swap;
  src: url("/public/Fonts/LegacySansStd-Book.otf");
}

@font-face {
  font-family: "LegacySansStd-BookItalic";
  font-display: swap;
  src: url("/public/Fonts/LegacySansStd-BookItalic.otf");
}

@font-face {
  font-family: "LegacySansStd-Bold";
  font-display: swap;
  src: url("/public/Fonts/LegacySansStd-Bold.otf");
}

@font-face {
  font-family: "FranklinGothicStd-Med";
  font-display: swap;
  src: url("/public/Fonts/ITCFranklinGothicStd-Med.otf");
}

@font-face {
  font-family: "FranklinGothicStd-Italic";
  font-display: swap;
  src: url("/public/Fonts/ITCFranklinGothicStd-HvyIt.otf");
}

@font-face {
  font-family: "LegacySansStd";
  font-display: swap;
  src: url("/public/Fonts/LegacySansStd-Medium.otf");
}

@font-face {
  font-family: "ITCFranklinGothicStd-Book";
  font-display: swap;
  src: url("/public/Fonts/ITCFranklinGothicStd-Book.otf");
}

@font-face {
  font-family: "Dearest";
  font-display: swap;
  src: url("/public/Fonts/Dearest.acb0237d3ecb04999a6e.ttf");
}

@font-face {
  font-family: "LegacySansStd-MediumItalic";
  font-display: swap;
  src: url("/public/Fonts/LegacySansStd-MediumItalic.otf");
}
