.deatil_container {
  width: 100%;
  height: 100vh;
  position: relative;
}

.deatil_container img {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
}

.deatil_container div {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 5% 10%;
}

.deatil_container .titleChapter {
  font-family: LegacySansStd-BookItalic;
  font-size: 22px;
  color: #3c3c3b;
  text-align: center;
  margin-top: 10px;
}

.deatil_container .subtitleChapter {
  font-family: LegacySansStd-BookItalic;
  font-size: 42px;
  color: #432918;
  text-align: center;
  margin-top: 20px;
}

.deatil_container .textChapter {
  font-family: LegacySansStd;
  font-size: 27px;
  color: #575756;
  margin-top: 20px;
  padding-right: 10px;
  text-align: justify;
  max-height: 75vh;
  overflow-y: scroll;
}

.deatil_container .textChapter .videoChapter {
  width: 50%;
  margin: 20px 24% 30px;
  border-radius: 50%;
  -webkit-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.61);
  box-shadow: 10px 10px 23px 0px rgba(0, 0, 0, 0.61);
}

.deatil_container .textChapter::-webkit-scrollbar {
  width: 8px;
  margin-right: 10px;
}

.deatil_container .textChapter:first-letter {
  font-family: Dearest;
  color: #543a10;
  float: left;
  font-size: 5.4em;
  line-height: 1;
  margin-right: 0.6rem;
}

@media screen and (max-width: 780px) {
  .deatil_container {
    height: 60vh;
  }

  .deatil_container img {
    margin-left: -2px;
  }

  .deatil_container div {
    height: auto;
    padding: 10% 3% 10% 7%;
  }

  .deatil_container .titleChapter {
    font-size: 19px;
    margin-top: 0px;
  }

  .deatil_container .subtitleChapter {
    font-size: 29px;
    margin-top: 10px;
  }

  .deatil_container .textChapter {
    font-size: 17px;
    margin-top: 10px;
    max-height: 40vh;
  }

  .deatil_container .textChapter:first-letter {
    font-size: 3em;
    line-height: 1;
    margin-right: 0.6rem;
  }
}

@media screen and (max-width: 425px) {
  .deatil_container {
    height: 68.6vh;
  }
  .deatil_container .titleChapter {
    font-size: 17px;
  }

  .deatil_container .subtitleChapter {
    font-size: 25px;
  }

  .deatil_container .textChapter {
    font-size: 15px;
    max-height: 48vh;
  }
}
