@import url("fonts.css");

html,
body {
  padding: 0;
  margin: 0;
  color: #dadada;
}
body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  background: black;
  background-image: url("/public/img/scroll_bar.png");
  background-size: cover;
  background-position: center;
  border: none;
}

body::-webkit-scrollbar-thumb {
  background-image: url("/public/img/scroll.png");
  background-size: cover;
  background-position: center;
  border-radius: 20px;
}

* {
  box-sizing: border-box;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.container-backgorund {
  background-color: transparent;
  background-image: url("/public/img/background.png");
  background-size: cover;
  background-position: center;
}

.continer2Col {
  display: grid;
  align-items: start;
  grid-template-columns: 1fr 1fr;
}

.continer2ColUnited {
  display: grid;
  align-items: center;
  align-content: start;
  grid-template-columns: 1fr 5fr;
  margin-top: 6%;
}

.containerLoading {
  width: 100%;
  height: 100vh;
  position: relative;
  display: grid;
  align-content: center;
  justify-items: center;
}

.backgroundLoading {
  position: absolute;
  height: 100%;
  display: block;
}

.loading {
  display: grid;
  align-content: center;
  justify-items: center;
  position: relative;
}

.lds-spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 8px;
  height: 8px;
  border-radius: 20%;
  background: #ac4d4c;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
    scale: 1.6;
  }
  100% {
    opacity: 0;
    scale: 0.8;
  }
}
.loading h2 {
  font-family: LegacySansStd;
  font-size: 20px;
  color: #dadada;
}

.loading img {
  width: 20%;
}

@media only screen and (min-width: 1200px) {
  .backgroundLoading {
    width: 100%;
  }
  .loading h2 {
    font-size: 25px;
  }

  .loading img {
    width: 25%;
  }
  @keyframes lds-spinner {
    0% {
      scale: 2;
    }
    100% {
      scale: 1;
    }
  }
}

@media screen and (max-width: 780px) {
  .containerLoading {
    width: 100%;
    height: 100vh;
  }

  .backgroundLoading {
    width: 100%;
    height: 100%;
  }
  .loading h2 {
    font-size: 15px;
    padding: 0px 20px;
  }
  .loading img {
    width: 40%;
  }
}
