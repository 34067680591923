.container {
  width: 100%;
  margin-top: -8px;
  display: block;
}
.container video {
  width: 100%;
}

.container img {
  background-color: transparent;
  background-image: url("/public/img/Home/background_image.png");
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 35%;
  right: 7%;
  width: 20%;
}

.container_tower,
.container_town {
  width: 100%;
  text-align: center;
}

.imgBar {
  margin-top: -60px;
  margin-bottom: 30px;
}

.container_tower h1,
.container_town h1 {
  display: grid;
  justify-content: center;
  font-family: FranklinGothicStd-Med;
  font-size: 43px;
  color: #dd9633;
  text-transform: uppercase;
  margin: 60px 0;
}

.container_tower .tower_name {
  font-family: FranklinGothicStd-Med;
  font-size: 28px;
  color: #dd9633;
  text-transform: uppercase;
  margin-bottom: 60px;
  margin-top: 20px;
}

.container_tower .tower_desc {
  width: 60%;
  margin: auto;
  font-family: LegacySansStd-Book;
  font-size: 35px;
  color: #dadada;
  text-align: center;
}

.container_town .twon_desc {
  width: 85%;
  margin: auto;
  font-family: LegacySansStd-Book;
  font-size: 35px;
  color: #dadada;
  text-align: center;
  margin-top: 10px;
}

.container_town .container_town_title {
  background-image: url("/public/img/Home/box_text.png");
  background-size: cover;
  background-position: center;
  width: 650px;
  margin-top: -17%;
  text-align: center;
}

.container_town .container_town_title .town_title {
  font-family: FranklinGothicStd-Italic;
  font-size: 49px;
  color: #dd9633;
  text-transform: uppercase;
}

.container_town .container_town_title .town_name {
  font-family: FranklinGothicStd-Med;
  font-size: 109px;
  color: #dadada;
  text-transform: uppercase;
}

.towers {
  width: 90% !important;
  margin: auto;
}

.towers img {
  width: 30%;
}

.town,
.tower {
  width: 80%;
  margin: auto;
  display: grid;
  justify-items: center;
  align-items: center;
}

.towerImg {
  width: 100%;
  background-color: transparent;
  background-image: url("/public/img/Home/malla.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: grid;
  justify-items: center;
  align-items: center;
}

.towns img {
  width: 80%;
}

.container_networks {
  display: grid;
  justify-content: center;
  width: 90%;
  margin: auto;
}

.container_networks h1 {
  display: grid;
  justify-content: center;
  font-family: FranklinGothicStd-Med;
  font-size: 43px;
  color: #dd9633;
  text-transform: uppercase;
  margin: 60px 0 -8px 0;
}

.container_networks .imgBar {
  margin: 10px auto;
}

.socialNetwork {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(4, 1fr);
}

.socialNetworkImg {
  width: 100%;
}

.button_next {
  color: red !important;
}

.imgDrakie {
  position: absolute;
  margin-top: 40px;
}

.container_shop {
  width: 100%;
  text-align: center;
  margin-top: 100px;
}

.container_shop .imgBar {
  margin: auto;
}

.container_shop .title_shop {
  font-family: FranklinGothicStd-Med;
  font-size: 109px;
  color: #dadada;
  text-transform: uppercase;
}

.container_shop .imgShop {
  margin: 50px auto;
  width: 75%;
}

.container_shop .imgShop:hover {
  cursor: pointer;
}

@media screen and (max-width: 780px) {
  .container img {
    top: 12%;
  }

  .container_tower h1,
  .container_town h1 {
    font-size: 19px;
    margin: 30px 0;
  }

  .imgBar {
    margin-top: -px;
    margin-bottom: 20px;
    width: 80%;
  }

  .container_tower .tower_name {
    font-size: 13px;
    margin-bottom: 40px;
    margin-top: 10px;
  }
  .container_tower .tower_desc {
    width: 90%;
    margin: auto;
    font-family: LegacySansStd-Book;
    font-size: 16px;
    color: #dadada;
    text-align: center;
  }

  .town,
  .tower {
    width: 100%;
  }

  .towerImg {
    width: 120%;
  }

  .container_town .twon_desc {
    width: 90%;
    font-size: 16px;
    margin-top: 15px;
  }

  .container_town .container_town_title {
    width: 250px;
    margin-top: -18.3%;
  }

  .container_town .container_town_title .town_title {
    font-size: 20px;
  }

  .container_town .container_town_title .town_name {
    font-size: 44px;
  }
  .town img {
    width: 100%;
  }

  .container_networks {
    width: 80%;
    margin: auto;
    margin-top: 0;
  }

  .container_networks h1 {
    font-size: 19px;
    margin-top: auto;
  }

  .container_networks .imgBar {
    width: 100%;
  }

  .imgDrakie {
    width: 25%;
    margin-top: 120px;
    margin-left: -40px;
  }

  .socialNetwork {
    grid-template-columns: repeat(2, 1fr);
    margin: -30px 30px 0 30px;
  }

  .container_shop {
    margin-top: 0px;
  }

  .container_shop .imgBar {
    margin: auto;
  }

  .container_shop .title_shop {
    font-size: 58px;
  }

  .container_shop .imgShop {
    margin: 20px auto;
  }
}
