h1,
h2,
h3,
h4,
button,
p {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

.conteinerTower {
  width: 100%;
  height: 100vh;
  position: relative;
  display: block;
}

.backgroundTower {
  position: absolute;
  width: 100%;
  height: 103vh;
  display: block;
}

.containerDetail {
  display: grid;
  justify-content: center;
  position: relative;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  margin: 0 15%;
  padding-top: 3%;
}

.containerDetail .imgMyths {
  width: 100%;
}

.detailTower h1 {
  font-family: FranklinGothicStd-Med;
  font-size: 108px;
  color: #dd9633;
  text-transform: uppercase;
  text-align: center;
}
.detailTower .barTower {
  width: 100%;
}

.detailTower .conteinerTitleMyth {
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr 1fr;
}

.detailTower .conteinerTitleMyth h2 {
  font-family: FranklinGothicStd-Italic;
  text-transform: uppercase;
  font-size: 59px;
  font-weight: lighter;
  color: #dadada;
}

.detailTower .conteinerTitleMyth p {
  font-family: FranklinGothicStd-Italic;
  text-transform: uppercase;
  font-weight: lighter;
  font-size: 40px;
  color: #dd9633;
}

.detailTower p {
  font-family: LegacySansStd;
  font-size: 22px;
  color: #dadada;
}

.detailTower .subtitle2Tower {
  font-family: LegacySansStd-Bold;
  font-size: 27px;
  color: #dd9633;
}

.symbolGIF {
  width: 70%;
}

.containerFlex {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  column-gap: 10px;
  margin-top: 20px;
  position: relative;
}

.containerFlex div {
  width: 120px;
  text-align: center;
}

.containerFlex div .continerStuff {
  display: grid;
}

.containerFlex .continerStuff .imgBox {
  width: 100%;
}

.containerFlex .continerStuff .imgStuff {
  position: absolute;
  width: 90px;
  align-self: center;
  justify-self: center;
}

.containerFlex .titleStuff {
  font-family: ITCFranklinGothicStd-Book;
  font-size: 15px;
  color: #cc8938;
  text-transform: uppercase;
  margin-top: 5px;
}

.containerFlex .nameStuff {
  font-family: FranklinGothicStd-Med;
  font-size: 20px;
  color: #dadada;
}

.containerMenu {
  display: grid;
  justify-content: center;
  position: absolute;
  width: 100%;
  padding: 0 15%;
  grid-template-columns: 1fr 3fr 1fr;
}

.containerButtons {
  display: grid;
  justify-content: center;
  align-items: center;
  align-content: center;
  grid-template-columns: repeat(6, 1fr);
}

.containerButtons div {
  font-family: FranklinGothicStd-Med;
  color: #dd9633;
  font-size: 17px;
  text-align: center;
}

.titleTower {
  font-family: FranklinGothicStd-Med;
  font-size: 37px;
  color: #dadada;
  text-transform: uppercase;
}

.containerButtons .towerActived {
  height: 80px;
  position: absolute;
  align-self: center;
}

.containerButtons .towerActivedImg {
  position: relative;
  width: 48px;
  margin: 16px;
}

.containerFiltro {
  width: 70%;
  margin: 0 auto;
}

.containerFiltro > div:nth-child(2n + 1) {
  display: grid;
  align-items: center;
  align-content: start;
  grid-template-columns: 1fr 10fr;
  margin-top: 5%;
  width: 60%;
}
.containerFiltro > div:nth-child(2n) {
  display: grid;
  align-items: start;
  align-content: start;
  grid-template-columns: 1fr 5fr;
  column-gap: 15px;
}

.containerFiltro .titleCharacter {
  font-family: FranklinGothicStd-Med;
  font-size: 63px;
  color: #dadada;
  text-transform: uppercase;
}

.containerFiltro .subTitleCharacter {
  font-family: LegacySansStd-MediumItalic;
  font-size: 28px;
  color: #dd9633;
  text-transform: uppercase;
}

.containerFiltro li {
  list-style: none;
  margin: 3% 0px;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 6fr;
}

.containerFiltro li label {
  font-family: LegacySansStd-Book;
  font-size: 24px;
  color: #dadada;
}

.containerFiltro li input {
  cursor: pointer;
  background-image: url("/public/img/Games/checkbox.png");
  background-color: transparent;
  background-size: cover;
  background-position: center;
  width: 24px;
  height: 24px;
  appearance: none;
}

.containerFiltro li input:checked {
  background-image: url("/public/img/Games/checkbox_checked.png");
}

.containerFiltrado {
  display: grid;
  align-items: center;
  align-content: start;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  min-height: 740px;
}
.containerFiltradoWeapons {
  display: grid;
  align-items: center;
  align-content: start;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 10px;
  row-gap: 10px;
  min-height: 570px;
}


.containerFiltrado button:hover,
.containerFiltradoWeapons button:hover {
  cursor: pointer;
}
.containerButtonPage {
  width: 27%;
  margin: 20px auto;
  display: grid;
  align-items: center;
  align-content: center;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 10px;
  row-gap: 10px;
}

.containerButtonPage button:hover {
  cursor: pointer;
}

.containerButtonPage p {
  text-align: center;
  font-family: LegacySansStd;
  font-size: 23px;
  color: #dadada;
}

.containerButtonPage p span {
  border: 2px solid #dadada;
  padding: 2px 9px;
  -moz-border-radius: 7px;
  -webkit-border-radius: 7px;
}

