.header-back {
  width: 100%;
  padding: 0;
  margin: 0;
}

.header {
  display: grid;
  grid-template-columns: 1fr 5fr;
  padding: 1% 10%;
  width: 100%;
  column-gap: 10px;
  justify-items: center;
  align-items: center;
}

.header--menu {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  justify-self: end;
  width: 70%;
}

.header--button-text {
  font-family: FranklinGothicStd-Med;
  color: #dadada;
  font-size: 24px;
}

.actived {
  color: #db9643;
}

.shadow-header {
  position: absolute;
  width: 100%;
  top: 11%;
  left: 0;
  right: 0;
}

.logo {
  width: 100%;
}

@media screen and (max-width: 780px) {
  .header-back {
    height: 70px;
  }
  .shadow-header {
    top: 7%;
  }
  .logo {
    width: 90px;
  }
  .header--button-text {
    font-size: 14px;
  }
}
