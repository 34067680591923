@import url("fonts.css");

html,
body {
  padding: 0;
  margin: 0;
  color: #dadada;
}
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: transparent;
  background-image: url("/public/img/scroll_bar.png");
  background-size: cover;
  background-position: center;
  border: none;
}

*::-webkit-scrollbar-thumb {
  background-image: url("/public/img/scroll.png");
  background-size: cover;
  background-position: center;
  border-radius: 20px;
}

* {
  box-sizing: border-box;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.container-backgorund {
  background-color: transparent;
  background-image: url("/public/img/background.png");
  background-size: cover;
  background-position: center;
}

.continer2Col {
  display: grid;
  align-items: start;
  grid-template-columns: 1fr 1fr;
}

.continer2ColUnited {
  display: grid;
  align-items: center;
  align-content: start;
  grid-template-columns: 1fr 5fr;
}

.top6 {
  margin-top: 6%;
}

a {
  text-decoration: none;
}
