.story {
  width: 100%;
  height: 114vh;
  position: relative;
  margin-top: -8px;
}

.container {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 5fr;
  column-gap: 30px;
  margin: 10px 15%;
  padding-top: 4%;
  position: relative;
  height: 80%;
}

.background {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
}

.banner_final {
  width: 100%;
}

.container .title {
  font-family: LegacySansStd;
  font-size: 47px;
  color: #dadada;
  text-transform: uppercase;
}

.menu_container {
  width: 100%;
  height: 100%;
  position: relative;
}

.content_chapterList ul {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 5%;
  list-style: square url("../../../public/img/Story/square.png");
}

.content_chapterList ul li:hover {
  cursor: pointer;
}
.content_chapterList ul li p:nth-child(2n + 1) {
  font-family: LegacySansStd-Book;
  font-size: 24px;
  color: #dadada;
  text-transform: uppercase;
}
.content_chapterList ul li p:nth-child(2n) {
  font-family: LegacySansStd-BookItalic;
  font-size: 24px;
  color: #dd9633;
}

.content_chapterList ul li p:nth-child(2n).active {
  color: #c7c385;
}

.content_chapterList h2 {
  margin-bottom: -2%;
}

.menu_container .backgroundLateral {
  position: absolute;
  width: 100%;
  height: 91vh;
  display: block;
}

@media screen and (max-width: 780px) {
  .story {
    margin-top: -10px;
  }

  .container {
    grid-template-columns: 1fr;
    margin: 0;
  }

  .background {
    width: 100%;
    height: 100%;
  }
  .container .title {
    font-size: 38px;
    margin-left: 20px;
  }

  .content_chapterList ul li p:nth-child(2n + 1) {
    font-size: 17px;
  }
  .content_chapterList ul li p:nth-child(2n) {
    font-size: 17px;
  }

  .menu_container {
    margin-top: -20px;
  }
}
