.footer {
  padding: 1% 18%;
  width: 100%;
  position: absolute;
  background-color: #130715;
}

.footer p {
  font-family: LegacySansStd-Book;
  font-size: 1.5em;
}

.footer--container {
  display: grid;
  grid-template-columns: 4fr 2fr 3fr;
  align-items: center;
}

.footer--PoweredBy {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  align-items: center;
  justify-self: start;
}

.footer--container-PressKit {
  border-left: 3px solid #dadada;
  display: grid;
  justify-items: center;
  align-items: center;
  height: 100%;
}

.footer--PressKit img:hover {
  content: url("../../../public/img/Footer/press_kit_focused.png");
}

.footer--follow {
  border-left: 3px solid #dadada;
  padding-left: 5%;
  height: 100%;
}

.footer--follow p {
  font-size: 1em;
  white-space: nowrap;
  margin-top: 10px;
}

.footer--follow .subtitle {
  font-family: LegacySansStd-Bold;
  font-size: 2em;
  margin-top: 10px;
}
.footer--follow .footer--logos {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.footer--follow .footer--logos .Discord:hover {
  content: url("../../../public/img/Footer/discord_focused.png");
}

.footer--follow .footer--logos .Twitter:hover {
  content: url("../../../public/img/Footer/twitter_focused.png");
}

.footer--follow .footer--logos .Instagram:hover {
  content: url("../../../public/img/Footer/instagram_focused.png");
}

.footer--follow .footer--logos .Tiktok:hover {
  content: url("../../../public/img/Footer/tiktok_focused.png");
}

@media screen and (max-width: 780px) {
  .footer {
    padding: 20px 8%;
  }
  .footer--container {
    grid-template-columns: 1fr;
  }
  .footer--container a img{
    width: 100%;
  }
  .footer--container-PressKit,
  .footer--follow {
    border-left: none;
    margin-top: 20px;
  }
  .footer--follow .footer--logos {
    column-gap: 20px;
  }

}
